import "./Footer.css";

export const Footer = () => {
  return (
    <footer>
      <div id="logos"></div>
      <p>Droits réservés</p>
      <p>Adresse</p>
      <p>0000000000</p>
      <p>email@email.com</p>
    </footer>
  );
};
