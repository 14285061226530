import { MyButton } from "../MyButton/MyButton";
import "./Diaporama.css";
import img_btn_left from "../../assets/icons/chevron_left_black_36dp.svg";
import img_btn_right from "../../assets/icons/navigate_next_black_36dp.svg";
import { useEffect, useState } from "react";

export const Diaporama = (props) => {
  const slides = props.slides;
  const [marginFirstSlide, setMarginFirstSlide] = useState(0);

  const handleClickBtnLeft = () => {
    if (marginFirstSlide === 0) {
      setMarginFirstSlide(-300);
    } else {
      setMarginFirstSlide(marginFirstSlide + 100);
    }
  };

  const handleClickBtnRight = () => {
    if (marginFirstSlide === -300) {
      setMarginFirstSlide(0);
    } else {
      setMarginFirstSlide(marginFirstSlide - 100);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("interval");
      if (marginFirstSlide === -300) {
        setMarginFirstSlide(0);
      } else {
        setMarginFirstSlide(marginFirstSlide - 100);
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div
      id="slider"
      // onMouseEnter={() => setIsOnSlider(true)}
      // onMouseOut={() => setIsOnSlider(false)}
    >
      <button id="btn_left" type="button" onClick={() => handleClickBtnLeft()}>
        <img src={img_btn_left} alt="bouton gauche" />
      </button>
      <button
        id="btn_right"
        type="button"
        onClick={() => handleClickBtnRight()}
      >
        <img src={img_btn_right} alt="bouton droit" />
      </button>
      <div className="slides">
        {slides.map((slide) => {
          const style = { backgroundImage: `url(${slide.image})` };

          if (slide.id === 1) {
            style["marginLeft"] = marginFirstSlide + "vw";
          }

          return (
            <div
              className={slide.id === 1 ? "slide first" : "slide"}
              key={slide.id}
              style={style}
            >
              {slide.hasDescription && (
                <div className="backgroundDiv">
                  <div className="description">
                    <h2>{slide.title}</h2>
                    <p>{slide.text}</p>
                    <MyButton
                      button_link={slide.button_link}
                      button_text={slide.button_text}
                      button_color={slide.button_color}
                      button_height={55}
                      button_width={200}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
