import { useLocation, useParams } from "react-router-dom";
import articletest from "../../assets/articletest.json";
import "./Article.css";
import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";
import { Loading } from "../Loading/Loading";

export const Article = (props) => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(props.article);
  const storage = getStorage();
  const location = useLocation();

  useEffect(() => {
    if (!props.article) {
      const articleReference = ref(
        storage,
        `gs://bss-website-d32f2.appspot.com/articles/article${articleId}/article${articleId}`
      );
      //console.log(`articles/article${articleId}/article${articleId}`);
      getBlob(articleReference).then((articleBlob) => {
        //console.log("getttedddddd");
        const fr = new FileReader();
        fr.addEventListener("load", (e) => {
          //console.log(e.target.result, JSON.parse(fr.result));
          setArticle(JSON.parse(fr.result).article);
        });
        fr.readAsText(articleBlob);
      });
    }
  }, []);
  const article_json = articletest;
  let paragraphe_counter = 0;
  let image_counter = 0;
  let link_counter = 0;

  return (
    <div className="page">
      {article && (
        <div className="article">
          {article.map((element) => {
            switch (element.type) {
              case "title":
                return <h1>{element.text}</h1>;
              case "subtitle":
                return <h2>{element.text}</h2>;
              case "paragraphe":
                return <p>{element.text}</p>;
              case "image":
                return <img src={element.url} alt={element.alt} />;
              default:
                break;
            }
          })}
        </div>
      )}
      {!article && <Loading />}
    </div>
  );
};
