import { MyButton } from "../MyButton/MyButton";
import "./HorizontalCard.css";
import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";

export const HorizontalCard = (props) => {
  const article = props.article;
  const [image, setImage] = useState({});
  const [title, setTitle] = useState("");
  const [paragraphe, setParagraphe] = useState("");
  const articleId = props.articleId;
  console.log(`call card with article : ${article}`);

  if (article && article.length > 0) {
    article.map((element) => {
      switch (element.type) {
        case "title":
          if (title === "") {
            setTitle(element.text);
          }
          break;
        case "paragraphe":
          if (paragraphe === "") {
            setParagraphe(element.text);
          }
          break;
        case "image":
          if (Object.keys(image).length === 0) {
            setImage({
              url: element.url,
              alt: element.alt,
            });
          }
          break;
        default:
          break;
      }
    });
    // if (Object.keys(image).length === 0) {
    //   setImage({
    //     url: "https://img.freepik.com/photos-premium/pile-livres-table-bibliotheque_130388-600.jpg",
    //     alt: "example",
    //   });
    // }
  }

  //const height = props.card_height ? props.card_height : "100px";
  const width = props.card_width ? props.card_width : "90%";
  const style = {
    //height: height,
    width: width,
  };

  return (
    <div className="horizontaCard" style={style}>
      <img
        src={
          image.url
            ? image.url
            : "https://img.freepik.com/photos-premium/pile-livres-table-bibliotheque_130388-600.jpg"
        }
        alt={image.alt ? image.alt : "article"}
      />
      <div className="textAndButton">
        <h2>{title}</h2>
        <p>{paragraphe}</p>
        <MyButton
          button_text={"Lire l'article"}
          articleId={articleId}
          article={article}
        />
      </div>
    </div>
  );
};
