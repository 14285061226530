import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";
import { Card } from "../../components/Card/Card";
import { HorizontalCard } from "../../components/HorizontalCard/HorizontalCard";
import "./Actualitees.css";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading/Loading";
import { ShowActualitees } from "./ShowActualitees";

export const Actualitees = (props) => {
  const [articles, setArticles] = useState([]);
  const articlesSaved = {};
  const storage = getStorage();
  const [onLoad, setOnLoad] = useState(true);
  const [page, setPage] = useState(0);
  const pagination = 10;
  const lastArticleId = props.lastArticleId;
  const lastPage = Math.trunc(lastArticleId / pagination);
  //const articleIds = [];
  let articleId;

  const goToPage = (page) => {
    setOnLoad(true);
    setPage(page);
    const array = [];
    const jobs = [];
    for (
      let i = lastArticleId - page * pagination;
      i > lastArticleId - pagination && i > 0;
      i--
    ) {
      jobs.push(
        new Promise((resolve, reject) => {
          const articleReference = ref(
            storage,
            `gs://bss-website-d32f2.appspot.com/articles/article${i}/article${i}`
          );
          getBlob(articleReference).then((articleBlob) => {
            const fr = new FileReader();
            fr.addEventListener("load", (e) => {
              array.push(JSON.parse(fr.result).article);
              articlesSaved[i] = JSON.parse(fr.result).article;
              resolve();
            });
            fr.readAsText(articleBlob);
          });
        })
      );
    }

    Promise.all(jobs).then(() => {
      const arraySorted = array.sort((a, b) => {
        return b[0].articleId - a[0].articleId;
      });
      setArticles([...arraySorted]);
      //const sub = page - 1;
      //const tempArticleId = lastArticleId - pagination * sub;
      // setTimeout(() => setOnLoad(false), 2000);
      setOnLoad(false);
    });
  };

  // remplir array puis après promise all set le state ?
  useEffect(() => {
    goToPage(0);
  }, []);

  return (
    <div id="actus">
      {/* {!onLoad && <ShowActualitees articles={articles} />}
      {onLoad && <Loading />} */}

      <div id="actus">
        <h1>Nos dernières actualitées</h1>
        <div id="cards">
          {!onLoad &&
            articles.map((article) => {
              return (
                <HorizontalCard
                  key={article[0].articleId}
                  article={article}
                  articleId={article[0].articleId}
                />
              );
            })}
          <div id="actus_btn">
            {!onLoad && (
              <button
                id="btnPrevious"
                onClick={() => {
                  if (page - 1 >= 0) {
                    goToPage(page - 1);
                  }
                }}
              >
                Page précédente
              </button>
            )}
            {!onLoad && (
              <div>
                <p>Nombre d'articles total : {lastArticleId}</p>
              </div>
            )}
            {!onLoad && (
              <button
                id="btnNext"
                onClick={() => {
                  if (page + 1 <= lastPage) {
                    goToPage(page + 1);
                  }
                }}
              >
                Page suivante
              </button>
            )}
          </div>
          {onLoad && <Loading />}
        </div>
      </div>
    </div>
  );
};
