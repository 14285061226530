import { Navbar } from "../../components/Navbar/Navbar";
import icon_address from "../../assets/icons/place_black_36dp.svg";
import icon_time from "../../assets/icons/schedule_black_36dp.svg";
import icon_phone from "../../assets/icons/phone_black_36dp.svg";
import icon_email from "../../assets/icons/alternate_email_black_36dp.svg";
import icon_euro from "../../assets/icons/euro_black_36dp.svg";
import "./Bss.css";
import { Card } from "../../components/Card/Card";
import { Footer } from "../../components/Footer/Footer";
import { HorizontalCard } from "../../components/HorizontalCard/HorizontalCard";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading/Loading";
import { getArticleFromStorage } from "../../utils/Storage";

export const Bss = (props) => {
  const bss = props.ressources;
  const actualitees = bss.actualitees;
  const windowWidth = props.windowWidth;
  const firestore = getFirestore();
  const [articlesId, setArticlesId] = useState([]);
  const [article1, setArticle1] = useState([]);
  const [article2, setArticle2] = useState([]);
  const [noArticles, setNoArticles] = useState(false);
  const storage = getStorage();
  const noImage =
    "https://firebasestorage.googleapis.com/v0/b/bss-website-d32f2.appspot.com/o/assetstemp%2Fnon_dispo.jpg?alt=media";

  useEffect(() => {
    const bss_articles_doc_ref = doc(firestore, "articles", bss.id);
    getDoc(bss_articles_doc_ref)
      .then((docSnap) => {
        if (docSnap.data().articles_id) {
          setArticlesId(docSnap.data().articles_id);
          return docSnap.data().articles_id;
        } else {
          return;
        }
      })
      .then((articleIdArray) => {
        if (
          articleIdArray &&
          articleIdArray.length > 0 &&
          articleIdArray[articleIdArray.length - 1] !== 0
        ) {
          const length = articleIdArray.length;
          const articleReference = ref(
            storage,
            `gs://bss-website-d32f2.appspot.com/articles/article${
              articleIdArray[length - 1]
            }/article${articleIdArray[length - 1]}`
          );
          //console.log(`articles/article${articleId}/article${articleId}`);
          getBlob(articleReference).then((articleBlob) => {
            //console.log("getttedddddd");
            const fr = new FileReader();
            fr.addEventListener("load", (e) => {
              //console.log(e.target.result, JSON.parse(fr.result));
              // if (articleId === lastArticleId) {
              //   setArticle1(JSON.parse(fr.result).article);
              // } else {
              //   setArticle2(JSON.parse(fr.result).article);
              // }
              console.log(
                `setArticles with article ID : ${articleIdArray[length - 1]}`
              );
              setArticle1(JSON.parse(fr.result).article);
            });
            fr.readAsText(articleBlob);
          });

          if (articleIdArray.length > 1) {
            const length = articleIdArray.length;
            const articleReference = ref(
              storage,
              `gs://bss-website-d32f2.appspot.com/articles/article${
                articleIdArray[length - 2]
              }/article${articleIdArray[length - 2]}`
            );
            //console.log(`articles/article${articleId}/article${articleId}`);
            getBlob(articleReference).then((articleBlob) => {
              //console.log("getttedddddd");
              const fr = new FileReader();
              fr.addEventListener("load", (e) => {
                console.log(e.target.result, JSON.parse(fr.result));
                // if (articleId === lastArticleId) {
                //   setArticle1(JSON.parse(fr.result).article);
                // } else {
                //   setArticle2(JSON.parse(fr.result).article);
                // }
                console.log(
                  `setArticles with article ID : ${articleIdArray[length - 2]}`
                );
                setArticle2(JSON.parse(fr.result).article);
              });
              fr.readAsText(articleBlob);
            });
          }
        }
      })
      .catch((error) => {
        console.warn("no articles associated to this bss");
        setNoArticles(true);
        console.warn(error);
      });
  }, []);

  return (
    <div>
      <section id="general">
        <h1>La BSS de {bss.nom}</h1>
        <p> {bss.general.text}</p>
      </section>
      <section id="infos">
        <div id="infos_general">
          <div id="titre_infos">
            <h2>Infos pratique</h2>
          </div>

          <a
            className="info"
            alt="addresse"
            href={bss.infos.address.maps}
            target="_blank"
          >
            <img src={icon_address} alt="addresse" />
            <p>{bss.infos.address.text} </p>
          </a>
          <div className="info">
            <img src={icon_time} alt="horraires" />
            <div id="time">
              <p>{bss.infos.time.matin}</p>
              <p>{bss.infos.time.aprem}</p>
            </div>
          </div>
          <div className="info">
            <img src={icon_phone} alt="téléphone" />
            <p>{bss.infos.phone}</p>
          </div>
          <div className="info">
            <img src={icon_email} alt="email" />
            <p>{bss.infos.email}</p>
          </div>
          <div className="info">
            <img src={icon_euro} alt="euro" />
            <p>{bss.infos.euro}</p>
          </div>
        </div>
        <img
          id="image"
          src={bss.infos.image.src ? bss.infos.image.src : noImage}
          alt={bss.infos.image.alt}
        />
      </section>
      <section id="partner">
        <h2>{bss.partner.title}</h2>
        <pre>
          <p>{bss.partner.text}</p>
        </pre>
      </section>
      <section id="programme">
        <h2>Programme d'activités</h2>
        <iframe
          src="https://calendar.google.com/calendar/embed?src=f5afb6765581a63472d3103f5771cf59d4de60b49dea024118042ed821a2d700%40group.calendar.google.com&ctz=America%2FMartinique"
          style={{ border: 0 }}
          frameBorder="0"
          scrolling="no"
          title={bss.nom + "_calendar"}
        ></iframe>
      </section>
      <section id="actusBss">
        <h2>Dernière actualitées à la BSS de {bss.nom}</h2>
        {windowWidth > 1100 && article1.length > 0 && (
          <div id="bssCards">
            <Card
              articleId={articlesId[articlesId.length - 1]}
              article={article1}
            />
            {article2.length > 0 && (
              <Card
                articleId={articlesId[articlesId.length - 2]}
                article={article2}
              />
            )}
          </div>
        )}
        {windowWidth <= 1100 && article1.length > 0 && (
          <div id="bssCards">
            <HorizontalCard
              articleId={articlesId[articlesId.length - 1]}
              article={article1}
            />
            {article2.length > 0 && (
              <HorizontalCard
                articleId={articlesId[articlesId.length - 2]}
                article={article2}
              />
            )}
          </div>
        )}
        {article1.length === 0 && !noArticles && <Loading />}
        {noArticles && <p>Pas d'articles</p>}
      </section>
      <Footer />
    </div>
  );
};
