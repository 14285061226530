import logo from "./logo.svg";
import "./App.css";
import { Home } from "./pages/home/Home";
import { Association } from "./pages/association/Association";
import { Navbar } from "./components/Navbar/Navbar";
import { Bss } from "./pages/bss/Bss";
import { Actualitees } from "./pages/actualitees/Actualitees";
import ressources from "./assets/ressources.json";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/login/Login";
import { Article } from "./components/Article/Article";
import navBarRessources from "./assets/navbar_ressources.json";
import homeRessources from "./assets/home_ressources.json";
import actusRessources from "./assets/actus_ressources.json";
import associationRessources from "./assets/association_ressources.json";
import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { initializeApp } from "firebase/app";

// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1jWvSS4K5iMusMUv8reKWXcFk98MBhcM",
  authDomain: "bss-website-d32f2.firebaseapp.com",
  databaseURL: "https://bss-website-d32f2-default-rtdb.firebaseio.com",
  projectId: "bss-website-d32f2",
  storageBucket: "bss-website-d32f2.appspot.com",
  messagingSenderId: "535308292756",
  appId: "1:535308292756:web:475cb76019b69f226e9a81",
  measurementId: "G-Q5DVMQRVPP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const storage = getStorage();

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

function App() {
  const bssNameList = ressources.bss;
  const [lastArticleId, setLastArticleId] = useState(0);
  const lastActus = actusRessources.actus.slice(1);
  useEffect(() => {
    console.log(`default lastArticleId = ${lastArticleId}`);
    const docRef = doc(db, "articles", "lastArticleId");
    getDoc(docRef).then((docSnap) => {
      setLastArticleId(docSnap.data().id);
      console.log(`lastArticleId setted = ${docSnap.data().id}`);
    });
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Navbar ressources={navBarRessources} />
        {/* <Home ressources={ressources.home} /> */}
        {/* <Bss ressources={ressources.bss.tartane} /> */}
        {/* <Actualitees actus={ressources.actus} /> */}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                ressources={homeRessources}
                lastArticleId={lastArticleId}
                db={db}
                windowWidth={windowWidth}
              />
            }
          />
          <Route
            exact
            path="/association"
            element={<Association ressources={associationRessources} />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/actualitees"
            element={
              lastArticleId > 0 && (
                <Actualitees
                  actus={actusRessources.actus}
                  lastArticleId={lastArticleId}
                  db={db}
                />
              )
            }
          />
          {bssNameList.map((bssName) => {
            return (
              <Route
                exact
                path={"/bss/" + bssName}
                key={bssName}
                element={
                  <Bss
                    ressources={require("./assets/bss_" + bssName + ".json")}
                    windowWidth={windowWidth}
                  />
                }
              />
            );
          })}
          <Route exact path={"/articles/:articleId"} element={<Article />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
