import { MyButton } from "../MyButton/MyButton";
import "./Card.css";
import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";

export const Card = (props) => {
  const articleId = props.articleId;
  const storage = getStorage();

  const article = props.article;
  const [image, setImage] = useState({});
  const [title, setTitle] = useState("");
  const [paragraphe, setParagraphe] = useState("");
  const noImage =
    "https://firebasestorage.googleapis.com/v0/b/bss-website-d32f2.appspot.com/o/assetstemp%2Fnon_dispo.jpg?alt=media";

  if (article && article.length > 0) {
    article.map((element) => {
      switch (element.type) {
        case "title":
          if (title === "") {
            setTitle(element.text);
          }
          break;
        case "paragraphe":
          if (paragraphe === "") {
            setParagraphe(element.text);
          }
          break;
        case "image":
          if (Object.keys(image).length === 0) {
            setImage({
              url: element.url,
              alt: element.alt,
            });
          }
          break;
        default:
          break;
      }
    });
    // if (Object.keys(image).length === 0) {
    //   setImage({
    //     url: "https://img.freepik.com/photos-premium/pile-livres-table-bibliotheque_130388-600.jpg",
    //     alt: "example",
    //   });
    // }
  }

  const height = props.card_height ? props.card_height : "650px";
  const width = props.card_width ? props.card_width : "500px";
  const style = {
    height: height,
    width: width,
  };

  return (
    <div className="card" style={style}>
      <img
        src={image.url ? image.url : noImage}
        alt={image.alt ? image.alt : "article"}
      />
      <h2>{title}</h2>
      <div className="text_fadeout">
        <p>{paragraphe}</p>
      </div>
      <MyButton
        button_text={"Lire l'article"}
        articleId={articleId}
        article={article}
      />
    </div>
  );
};
