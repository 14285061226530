import "./Association.css";
import { Navbar } from "../../components/Navbar/Navbar";

export const Association = (props) => {
  const ressources = props.ressources;
  const article_json = ressources;
  let paragraphe_counter = 0;
  let image_counter = 0;
  let link_counter = 0;
  return (
    <div className="article">
      <h2>1/ L'association</h2>
      <h3>Qui sommes-nous ?</h3>
      <p>
        Les Cacaoyers est une association Loi 1901, créée en 2017, remise en
        activité en avril 2022, dans le prolongement de l'action « Dispositif
        expérimental jeunesses de Tartane » menée pour prévenir le décrochage
        social, scolaire et professionnel des jeunes, avec un accompagnement
        ajusté aux réalités des jeunes (entretiens individuels et de groupe,
        l'EA-CDPJ).
      </p>
      <p>
        Le bureau est composé de :
        <ul>
          <li>Présidente : Mme SILMAR Marie-Ange</li>
          <li>Vice-Président: M. PALIN Christian</li>
          <li>Secrétaire : Mme ROCH Valérie</li>
          <li>Trésorière : Mme SIGÈRE Sylvie</li>
        </ul>
      </p>

      <h2>Les statuts :</h2>
      <p>
        Les statuts de l'association Les Cacaoyers soulignent une visée
        intergénérationnelle dans ses actions et une attention particulière aux
        jeunes et au lien entre les habitants de la résidence et plus largement
        de la cité et du quartier. Cette visée forte se traduit à travers
        différentes actions.
      </p>
      <h2>Actions :</h2>
      <p>
        Différentes actions ont été menées et se prolongent avec le soutien de
        la ville de La Trinité (PEC et mise à disposition d'un local, de la
        préfecture de la Martinique (Projets T-AVFAJ, Aller Vers pour Faire Avec
        les Jeunes), le bailleur social la SIMAR.
      </p>
      <p>
        1/ Développement d'un réseau de Bibliothèques Solidaires et Sociales
        (BSS), aujourd'hui 6 BSS dans les maisons de quartier de la ville sont
        en cours de développement et des Kiosques Solidaires et Sociaux (KSS)
        sont en cours d'implantation, pour l'accès gratuit au livre, le
        développement du gout de lire dès le plus jeune âge, et la construction
        identitaire des jeunes martiniquais ;
      </p>
      <p>
        2/ Formation BAFA, les formateurs de la FOL sont venus sur les lieux de
        vie des jeunes pour les former
      </p>
      <p>
        3/ Organisation de « lecture offerte » : Les auteurs sont venus à la
        rencontre des jeunes présenter leur livre, échanger avec eux et
        dédicacer (Sonia Jean-Baptiste-Edouard pour la présentation de « Je
        serai champion olympique » biographie de Christophe Maleau)
      </p>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/bss-website-d32f2.appspot.com/o/pages%2Fassociation%2Fimages%2Flecture%20christophe%20malo.jpg?alt=media"
        alt="lecture christophe malo"
        width="25%"
      />
      <p>
        4/ Organisation de différents ateliers de couture, de musique, peinture,
        expression vocale (Slam) et corporelle (danse), jeux de société (jeux de
        Sabine Andrivon-Milton).
      </p>
      <p>
        5/ Formation à l'animation des séances de lecture et développement
        professionnel pour les adhérents de l'association :
        <ul>
          <li>
            - Conférences-débats avec les parents « Accompagner mon enfant vers
            la réussite dans… »
          </li>
          <li>
            - Les actions visant le lien intergénérationnel : fête de Noël,
            Carnaval, la Fête des voisins.
          </li>
        </ul>
      </p>
      <p>
        6/ Développement international avec le CARD de Montréal et la Fondatrice
        et Directrice Générale : Claudia Ouamabia.
      </p>
    </div>
  );
};
