import { NavLink } from "react-router-dom";
import "./Navbar.css";
import logoBis from "../../assets/logo_bss.jpg";
import menuIcon from "../../assets/icons/menu_black_36dp.svg";

export const Navbar = (props) => {
  const logo = props.ressources.logo;
  const links = props.ressources.links;

  const handleClick = () => {
    document.getElementById("navBarLinks").classList.toggle("mobile-menu");
  };

  return (
    <nav>
      <ul>
        <NavLink to={"/"}>
          <img src={logoBis} alt={logo.alt} />
        </NavLink>
        <div id="navBarLinks">
          {links.map((link) => {
            if (link.id === "bss") {
              return (
                <li key={link.id} id="bssLi">
                  {link.text}
                  <ul id="bssNavBar">
                    {link.links.map((linkBss) => {
                      return (
                        <li key={linkBss.id}>
                          <NavLink
                            to={linkBss.link}
                            className="navLink"
                            onClick={handleClick}
                          >
                            {linkBss.text}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={link.id}>
                  <NavLink
                    to={link.link}
                    className="navLink"
                    onClick={handleClick}
                  >
                    {link.text}
                  </NavLink>
                </li>
              );
            }
          })}
        </div>
        <img id="menuIcon" src={menuIcon} alt="menu" onClick={handleClick} />
      </ul>
    </nav>
  );
};
