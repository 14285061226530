import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getBlob,
} from "firebase/storage";

import "./LastActus.css";

import { Card } from "../Card/Card";
import { HorizontalCard } from "../HorizontalCard/HorizontalCard";

export const LastActus = (props) => {
  const lastArticleId = props.lastArticleId;
  console.log(`LAST ACTU articleId : ${lastArticleId}`);
  const storage = getStorage();

  const [article1, setArticle1] = useState([]);
  const [article2, setArticle2] = useState([]);

  const windowWidth = props.windowWidth;

  useEffect(() => {
    console.log(`updated lastArticleId = ${lastArticleId}`);
    if (lastArticleId !== 0) {
      for (
        let articleId = lastArticleId;
        articleId > lastArticleId - 2;
        articleId--
      ) {
        if (articleId !== 0) {
          //console.log("firebassse");
          // Create a reference from a Google Cloud Storage URI
          const articleReference = ref(
            storage,
            `gs://bss-website-d32f2.appspot.com/articles/article${articleId}/article${articleId}`
          );
          //console.log(`articles/article${articleId}/article${articleId}`);
          getBlob(articleReference).then((articleBlob) => {
            //console.log("getttedddddd");
            const fr = new FileReader();
            fr.addEventListener("load", (e) => {
              //console.log(e.target.result, JSON.parse(fr.result));
              if (articleId === lastArticleId) {
                setArticle1(JSON.parse(fr.result).article);
              } else {
                setArticle2(JSON.parse(fr.result).article);
              }
            });
            fr.readAsText(articleBlob);
          });
        }
      }
    }
  }, []);

  return (
    <section id="actualitees">
      <h1>Nos dernières actualitées</h1>
      {windowWidth > 1100 && (
        <div id="homeCards">
          <Card article={article1} articleId={lastArticleId} />
          <Card article={article2} articleId={lastArticleId - 1} />
        </div>
      )}
      {windowWidth <= 1100 && (
        <div id="homeCards">
          <HorizontalCard article={article1} articleId={lastArticleId} />
          <HorizontalCard article={article2} articleId={lastArticleId - 1} />
        </div>
      )}
    </section>
  );
};
