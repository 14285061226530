import { NavLink } from "react-router-dom";

export const MyButton = (props) => {
  const button_text = props.button_text;
  const button_color = props.button_color;
  const link = props.button_link;
  const height = props.button_height ? props.button_height : 40;
  const width = props.button_width ? props.button_width : 150;
  const article = props.article;
  //TODO ::::::
  const article_id = props.articleId;
  const path = props.path;
  //console.log(`button article id : ${props.articleId}`);
  const style = {
    backgroundColor: button_color ? button_color : "yellow",
    padding: "5px",
    height: height ? height + "px" : "10%",
    width: width ? width + "px" : "25%",
    borderRadius: "15px",
    fontSize: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <NavLink
      to={{
        pathname: path ? path : "/articles/" + article_id,
        article: { article: article },
      }}
      style={style}
      className="navLink"
    >
      {button_text}
    </NavLink>
  );
};
