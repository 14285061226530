import { Diaporama } from "../../components/Diaporama/Diaporama";
import { MyButton } from "../../components/MyButton/MyButton";
import { Navbar } from "../../components/Navbar/Navbar";
import { Card } from "../../components/Card/Card";
import "./Home.css";
import { Footer } from "../../components/Footer/Footer";
import { useRef } from "react";
import { HorizontalCard } from "../../components/HorizontalCard/HorizontalCard";
import { LastActus } from "../../components/LastActus/LastActus";
import { Loading } from "../../components/Loading/Loading";

export const Home = (props) => {
  const ressources = props.ressources;
  const presentation = ressources.presentation;
  const lastArticleId = props.lastArticleId;
  const windowWidth = props.windowWidth;

  return (
    <div>
      <Diaporama slides={ressources.slides} />
      <section id="presentation">
        <h1>{presentation.titre}</h1>
        <p>{presentation.paragraphe}</p>
        <MyButton
          button_text={presentation.button_text}
          button_color={presentation.button_color}
          button_width={presentation.button_width}
          button_height={presentation.button_height}
          path="/association"
        />
      </section>
      {lastArticleId !== 0 && (
        <LastActus lastArticleId={lastArticleId} windowWidth={windowWidth} />
      )}
      {lastArticleId === 0 && <Loading />}
      <Footer />
    </div>
  );
};
